var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { color: "primary", dark: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Open Dialog ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c("v-container", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "campaignLists dialogCampignList pa-5 mt-3",
                      },
                      [
                        _c(
                          "v-timeline",
                          { attrs: { "align-top": "", dense: "" } },
                          _vm._l(_vm.messages, function (message) {
                            return _c(
                              "v-timeline-item",
                              {
                                key: message.time,
                                attrs: { color: "", small: "" },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "timeline-text font-weight-normal",
                                    },
                                    [_vm._v(" " + _vm._s(message.from) + " ")]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }