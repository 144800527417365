<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <div class="campaignLists dialogCampignList pa-5 mt-3">
                <v-timeline
                    align-top
                    dense
                >
                    <v-timeline-item
                    v-for="message in messages"
                    :key="message.time"
                    color=""
                    small
                    >
                    <div>
                        <div class="timeline-text font-weight-normal">
                        {{ message.from }}
                        </div>
                    </div>
                    </v-timeline-item>
                </v-timeline>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,

      messages: [
          {
            from: `Fetching keywords for Brand`,
          },
          {
            from: 'Fetching keywords for Discovery',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
        ],
    }),
  }
</script>
